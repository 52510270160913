jQuery(function($) {
  var $smooth  = $('a[href^="#"]');
  // #で始まるアンカーをクリックした場合に処理
  $smooth.on('click', function() {
    // スクロールの速度
    var speed = 800;// ミリ秒
    // アンカーの値取得
    var href= $(this).attr("href");
    // 移動先を取得
    var target = $(href === '#top' || href === '#' || href === '' ? 'html' : href);
    // var nav = $("#nav").height();
    var header =  $('#header').outerHeight();
    // 移動先を数値で取得
    var position = target.offset().top - header;
    // スムーススクロール
    if( !$('body html').is(':animated') ) {
      $('body,html').stop().animate({scrollTop:position}, speed, 'swing');
    }
    return false;
  });

  var $onOff = $('.on_off'),
      $noChange = "none";
  $onOff.hover(function() {
    $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
  },
  function() {
    if (!$(this).hasClass($noChange)) {
      $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
    }
  });

  var $backBtn = $('#back-btn');
    $backBtn.on('click', function() {
    history.back();
  });

// header表示
  $(window).scroll(function(){
    var scr = $(document).scrollTop();
    var height = $("#trouble").offset().top;
    if(scr >= height){
        $("#header_navi").fadeIn();
      }else{
        $("#header_navi").fadeOut();
     }
  });


// ページトップへ追尾
  var topBtn=$('#go_top');
  topBtn.hide();
  //◇ボタンの表示設定
  $(window).scroll(function(){
  if($(this).scrollTop()>200){//---- 画面を80pxスクロールしたら、ボタンを表示する
    topBtn.fadeIn();
    }else{//---- 画面が80pxより上なら、ボタンを表示しない
    topBtn.fadeOut();
    }
  });
  // ◇ボタンをクリックしたら、スクロールして上に戻る
  topBtn.click(function(){
    $('body,html').animate({
    scrollTop: 0},500);
    return false;
  });

  // #で始まるアンカーをクリックした場合に処理
  $('a[href^="#"]').click(function() {
      // スクロールの速度
      var speed = 800;// ミリ秒
      // アンカーの値取得
      var href= $(this).attr("href");
      // 移動先を取得
      var target = $(href == "#" || href == "" ? 'html' : href);
      var header = $("header").outerHeight(true);
      // 移動先を数値で取得
      var position = target.offset().top - header;
      // スムーススクロール
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
  });


  // スクロールバーの横幅を取得
  $('html').append('<div class="scrollbar" style="overflow:scroll;"></div>');
  var scrollsize = window.innerWidth - document.documentElement.clientWidth;

  $('.scrollbar').hide();

  // 「.modal-open」をクリック
  $('.modal-open').click(function(){
      // html、bodyを固定（overflow:hiddenにする）
      $('html, body').addClass('lock');

      // オーバーレイ用の要素を追加
      $('body').append('<div class="modal-overlay"></div>');

      // オーバーレイをフェードイン
      $('.modal-overlay').fadeIn('slow');

      // モーダルコンテンツのIDを取得
      var modal = '#' + $(this).attr('data-target');

       // モーダルコンテンツを囲む要素を追加
      $(modal).wrap("<div class='modal-wrap'></div>");

      // モーダルコンテンツを囲む要素を表示
      $('.modal-wrap').show();

      // モーダルコンテンツの表示位置を設定
      modalResize();

       // モーダルコンテンツフェードイン
      $(modal).fadeIn('slow');

      // モーダルコンテンツをクリックした時はフェードアウトしない
      $(modal).click(function(e){
          e.stopPropagation();
      });

      // 「.modal-overlay」あるいは「.modal-close」をクリック
      $('.modal-wrap, .modal-close').off().click(function(){
          // モーダルコンテンツとオーバーレイをフェードアウト
          $(modal).fadeOut('slow');
          $('.modal-overlay').fadeOut('slow',function(){
              // html、bodyの固定解除
              $('html, body').removeClass('lock');
              // オーバーレイを削除
              $('.modal-overlay').remove();
              // モーダルコンテンツを囲む要素を削除
              $(modal).unwrap("<div class='modal-wrap'></div>");
         });
      });

      // リサイズしたら表示位置を再取得
      $(window).on('resize', function(){
          modalResize();
      });

      // モーダルコンテンツの表示位置を設定する関数
      function modalResize(){
          // ウィンドウの横幅、高さを取得
          var w = $(window).width();
          var h = $(window).height();

          // モーダルコンテンツの横幅、高さを取得
          var mw = $(modal).outerWidth(true);
          var mh = $(modal).outerHeight(true);

          // モーダルコンテンツの表示位置を設定
          if ((mh > h) && (mw > w)) {
              $(modal).css({'left': 0 + 'px','top': 0 + 'px'});
          } else if ((mh > h) && (mw < w)) {
              var x = (w - scrollsize - mw) / 2;
              $(modal).css({'left': x + 'px','top': 0 + 'px'});
          } else if ((mh < h) && (mw > w)) {
              var y = (h - scrollsize - mh) / 2;
              $(modal).css({'left': 0 + 'px','top': y + 'px'});
          } else {
              var x = (w - mw) / 2;
              var y = (h - mh) / 2;
              $(modal).css({'left': x + 'px','top': y + 'px'});
          }
      }

  });

});
